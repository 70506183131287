<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    // const deviceWidth = document.documentElement.clientWidth;
    // const scale = deviceWidth / 1920; // 分母-设计稿的尺寸
    // document.body.style.zoom = scale;

    // window.addEventListener("resize", () => {
    //   const deviceWidth = document.documentElement.clientWidth;
    //   const scale = deviceWidth / 1920; // 分母-设计稿的尺寸
    //   document.body.style.zoom = scale;
    // });
    this.$i18n.locale = 'zh';
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 !important;
  padding: 0 !important;
}
body,
html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
