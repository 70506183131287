import index from './index/index'
const data = {
    language: {
        name: 'English'
    },
    index: {
        index: index.EN.index,
        company: index.EN.company,
        developmentH: index.EN.developmentH,
        CorporateV: index.EN.CorporateV,
        ProductsAndS: index.EN.ProductsAndS,
        PerformanceP: index.EN.PerformanceP,
        OurQ: index.EN.OurQ,
        ContactUs: index.EN.ContactUs,
        companyName: index.EN.companyName,
        companyDetail: index.EN.companyDetail,
        introduction1: index.EN.introduction1,
        introduction2: index.EN.introduction2,
        introduction3: index.EN.introduction3,
        introduction4: index.EN.introduction4,
        development1: index.EN.development1,
        development2: index.EN.development2,
        development3: index.EN.development3,
        development4: index.EN.development4,
        development5: index.EN.development5,
        development6: index.EN.development6,
        development7: index.EN.development7,
        corporate1: index.EN.corporate1,
        corporate2: index.EN.corporate2,
        corporate3: index.EN.corporate3,
        corporate4: index.EN.corporate4,
        corporate5: index.EN.corporate5,
        corporate6: index.EN.corporate6,
        products1: index.EN.products1,
        products2: index.EN.products2,
        products3: index.EN.products3,
        products4: index.EN.products4,
        products5: index.EN.products5,
        products6: index.EN.products6,
        products7: index.EN.products7,
        products8: index.EN.products8,
        products9: index.EN.products9,
        productsB: index.EN.productsB,
        achievements1: index.EN.achievements1,
        achievements2: index.EN.achievements2,
        achievements3: index.EN.achievements3,
        achievements: index.EN.achievements,
        achievements4: index.EN.achievements4,
        achievements5: index.EN.achievements5,
        achievements6: index.EN.achievements6,
        achievements7: index.EN.achievements7,
        achievements8: index.EN.achievements8,
        address: index.EN.address,
        addressD: index.EN.addressD,
        phone: index.EN.phone,
        EnglishPhone: index.EN.EnglishPhone,
        website: index.EN.website,
        email: index.EN.email,
        submit: index.EN.submit,
    }
}
export default data