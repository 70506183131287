import { render, staticRenderFns } from "./BaseResizeFrame.vue?vue&type=template&id=c15e48dc&scoped=true"
import script from "./BaseResizeFrame.vue?vue&type=script&lang=js"
export * from "./BaseResizeFrame.vue?vue&type=script&lang=js"
import style0 from "./BaseResizeFrame.vue?vue&type=style&index=0&id=c15e48dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c15e48dc",
  null
  
)

export default component.exports