import index from './index/index'
const data = {
    language: {
        name: '中文'
    },
    index: {
        index: index.ZH.index,
        company: index.ZH.company,
        developmentH: index.ZH.developmentH,
        CorporateV: index.ZH.CorporateV,
        ProductsAndS: index.ZH.ProductsAndS,
        PerformanceP: index.ZH.PerformanceP,
        OurQ: index.ZH.OurQ,
        ContactUs: index.ZH.ContactUs,
        companyName: index.ZH.companyName,
        companyDetail: index.ZH.companyDetail,
        introduction1: index.ZH.introduction1,
        introduction2: index.ZH.introduction2,
        introduction3: index.ZH.introduction3,
        introduction4: index.ZH.introduction4,
        development1: index.ZH.development1,
        development2: index.ZH.development2,
        development3: index.ZH.development3,
        development4: index.ZH.development4,
        development5: index.ZH.development5,
        development6: index.ZH.development6,
        development7: index.ZH.development7,
        corporate1: index.ZH.corporate1,
        corporate2: index.ZH.corporate2,
        corporate3: index.ZH.corporate3,
        corporate4: index.ZH.corporate4,
        corporate5: index.ZH.corporate5,
        corporate6: index.ZH.corporate6,
        products1: index.ZH.products1,
        products2: index.ZH.products2,
        products3: index.ZH.products3,
        products4: index.ZH.products4,
        products5: index.ZH.products5,
        products6: index.ZH.products6,
        products7: index.ZH.products7,
        products8: index.ZH.products8,
        products9: index.ZH.products9,
        productsB: index.ZH.productsB,
        achievements1: index.ZH.achievements1,
        achievements2: index.ZH.achievements2,
        achievements3: index.ZH.achievements3,
        achievements: index.ZH.achievements,
        achievements4: index.ZH.achievements4,
        achievements5: index.ZH.achievements5,
        achievements6: index.ZH.achievements6,
        achievements7: index.ZH.achievements7,
        achievements8: index.ZH.achievements8,
        address: index.ZH.address,
        addressD: index.ZH.addressD,
        phone: index.ZH.phone,
        EnglishPhone: index.ZH.EnglishPhone,
        website: index.ZH.website,
        email: index.ZH.email,
        submit: index.ZH.submit,
    }
}

export default data