<template>
  <div class="home">
      <BaseResizeFrame :baseWidth="baseWidth" :baseHeight="baseHeight">
      <div class="top">
        <div class="topnav">
          <div
            style="
              width: 4.375rem;
              height: 4.375rem;
              flex: 1.5;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          ></div>
          <div style="flex: 4; display: flex; align-items: center">
            <div class="navtext">
              <!-- <div class="navcontent">{{$t('index.index')}}</div> -->
              <a href="#part1" class="navcontent">{{ $t("index.index") }}</a>
            </div>
            <div class="navtext">
              <!-- <div class="navcontent">{{ $t("index.company") }}</div> -->
              <a href="#part2" class="navcontent">{{ $t("index.company") }}</a>
            </div>
            <div class="navtext">
              <!-- <div class="navcontent">{{ $t("index.developmentH") }}</div> -->
              <a href="#part3" class="navcontent">{{
                $t("index.developmentH")
              }}</a>
            </div>
            <div class="navtext">
              <!-- <div class="navcontent">{{ $t("index.CorporateV") }}</div> -->
              <a href="#part4" class="navcontent">{{
                $t("index.CorporateV")
              }}</a>
            </div>
            <div class="navtext">
              <!-- <div class="navcontent">{{ $t("index.ProductsAndS") }}</div> -->
              <a href="#part5" class="navcontent">{{
                $t("index.ProductsAndS")
              }}</a>
            </div>
            <div class="navtext">
              <!-- <div class="navcontent">{{ $t("index.PerformanceP") }}</div> -->
              <a href="#part6" class="navcontent">{{
                $t("index.PerformanceP")
              }}</a>
            </div>
            <div class="navtext">
              <!-- <div class="navcontent">{{ $t("index.OurQ") }}</div> -->
              <a href="#part7" class="navcontent">{{ $t("index.OurQ") }}</a>
            </div>
            <div class="navtext">
              <!-- <div class="navcontent">{{ $t("index.ContactUs") }}</div> -->
              <a href="#part8" class="navcontent">{{
                $t("index.ContactUs")
              }}</a>
            </div>

            <div class="navtext" style="width: 160px; padding-right: 30px;font-size: 21px">
              <!-- <div class="navcontent">{{ $t("index.ContactUs") }}</div> -->
              <el-switch
                style="color: #f8f8f8;font-size: 21px;"
                v-model="language"
                active-color="#13ce66"
                active-text="English"
                inactive-text="中文"
                inactive-color="#ff4949"
                @change="changeLanguage"
              >
              </el-switch>
            </div>
          </div>
        </div>
        <div class="donglogo">
          <img src="../assets/homeLogo.png" alt="" />
        </div>
        <div class="company">{{ $t("index.companyName") }}</div>
        <!-- <div class="companyText">
        {{ $t("index.companyDetail") }}
      </div> -->
        <!-- <div class="companyBtn">查看详情</div> -->
      </div>
      <!-- 公司介绍 -->
      <div class="box2">
        <div class="introduction">
          <div
            id="part2"
            style="
              color: #333333;
              font-size: 3rem;
              font-family: SourceHanSansCN-Medium;
            "
          >
            {{ $t("index.company") }}
          </div>
          <!-- <div
          style="
            color: #aaaaaa;
            font-size: 3rem;
            font-family: SourceHanSansCN-Medium;
          "
        >
          /INTRODUCTION
        </div> -->
        </div>
        <div class="introductionContent">
          <div class="introductionContent-text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {{ $t("index.introduction1") }}
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t("index.introduction2") }}
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t("index.introduction3") }}
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t("index.introduction4") }}
          </div>
          <div class="introductionContent-img"></div>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="box3" id="part3">
        <div class="developmentTitle">
          <div class="developmentTitle-1">{{ $t("index.developmentH") }}</div>
          <!-- <div class="developmentTitle-2">/DEVELOPMENT COURSE</div> -->
        </div>
        <div class="developmentContent">
          <div class="developmentContent-top">
            <div class="developtext">
              <div
                style="
                  width: 18.75rem;
                  height: 3.375rem;
                  padding-top: 0.625rem;
                "
                class="devetxt"
              >
                {{ $t("index.development1") }}
              </div>
              <div
                style="position: absolute; top: 10.8125rem; left: 6.875rem"
                class="developtextYear"
              >
                2008
              </div>
            </div>
            <div class="developtext">
              <div
                style="
                  width: 18.9375rem;
                  height: 3.375rem;
                  padding-top: 1.25rem;
                "
                class="devetxt"
              >
                {{ $t("index.development2") }}
              </div>
              <div
                style="position: absolute; top: 10.8125rem; left: 6.875rem"
                class="developtextYear"
              >
                2013
              </div>
            </div>
            <div class="developtext">
              <div style="width: 18.875rem" class="devetxt">
                {{ $t("index.development3") }}
              </div>
              <div
                style="position: absolute; top: 10.8125rem; left: 6.875rem"
                class="developtextYear"
              >
                2017
              </div>
            </div>
            <div class="developtext">
              <div style="width: 25rem" class="devetxt">
                {{ $t("index.development4") }}
              </div>
              <div
                style="position: absolute; top: 10.8125rem; left: 6.875rem"
                class="developtextYear"
              >
                2023
              </div>
            </div>
          </div>
          <div class="developmentContent-bottom">
            <div style="flex: 1" class="developtext2"></div>
            <div style="flex: 2" class="developtext2">
              <div
                style="margin: 1.75rem 0 0 1.5625rem"
                class="developtextYear"
              >
                2011
              </div>
              <div style="width: 17.9375rem" class="devetxt">
                {{ $t("index.development5") }}
              </div>
            </div>
            <div style="flex: 2" class="developtext2">
              <div
                style="margin: 1.75rem 0 0 1.5625rem"
                class="developtextYear"
              >
                2015
              </div>
              <div style="width: 19.9375rem" class="devetxt">
                {{ $t("index.development6") }}
              </div>
            </div>
            <div style="flex: 3" class="developtext2">
              <div
                style="margin: 1.75rem 0 0 1.5625rem"
                class="developtextYear"
              >
                2019
              </div>
              <div style="width: 25rem" class="devetxt">
                {{ $t("index.development7") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background-color: #f8f8f8">
        <!-- 企业愿景 -->
        <div class="box4">
          <div
            id="part4"
            style="
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-top: 6.25rem;
            "
          >
            <div class="developmentTitle-1" style="color: #333333">
              {{ $t("index.CorporateV") }}
            </div>
            <!-- <div class="developmentTitle-2" style="color: #aaaaaa">
            /CORPORATE VISION
          </div> -->
          </div>
          <div class="visionContent">
            <div class="visionContent-card">
              <div style="flex: 1" class="cardContent">
                <div style="flex: 1" class="cardContent2">
                  <img src="../assets/user.png" alt="" />
                </div>
                <div style="flex: 2">
                  <div class="cardContent-title">
                    {{ $t("index.corporate1") }}
                  </div>
                  <div class="cardContent-text">
                    {{ $t("index.corporate2") }}
                  </div>
                </div>
              </div>
              <div style="flex: 1" class="cardContent">
                <div style="flex: 1" class="cardContent2">
                  <img src="../assets/user2.png" alt="" />
                </div>
                <div style="flex: 2">
                  <div class="cardContent-title">
                    {{ $t("index.corporate3") }}
                  </div>
                  <div class="cardContent-text">
                    {{ $t("index.corporate4") }}
                  </div>
                </div>
              </div>
              <div style="flex: 1" class="cardContent">
                <div style="flex: 1" class="cardContent2">
                  <img src="../assets/user3.png" alt="" />
                </div>
                <div style="flex: 2; margin-top: 5%">
                  <div class="cardContent-title">
                    {{ $t("index.corporate5") }}
                  </div>
                  <div class="cardContent-text">
                    {{ $t("index.corporate6") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 产品与服务 -->
        <div class="box5">
          <div
            id="part5"
            style="
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 6.25rem;
            "
          >
            <div class="developmentTitle-1" style="color: #333333">
              {{ $t("index.ProductsAndS") }}
            </div>
          </div>
          <div
            style="
              margin-top: 8.125rem;
              display: flex;
              justify-content: center;
              height: 23.125rem;
            "
          >
            <div class="productbox">
              <div class="productbox-content" style="margin-right: 3.125rem">
                <div
                  style="
                    width: 100%;
                    height: 7.5rem;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      width: 9.1875rem;
                      height: 9.1875rem;
                      position: absolute;
                      top: -3.1875rem;
                    "
                  >
                    <img src="../assets/product1.png" alt="" />
                  </div>
                </div>
                <div
                  style="display: flex; justify-content: center"
                  class="textproduct"
                >
                  {{ $t("index.products1") }}
                </div>
                <div class="textproduct2">{{ $t("index.products2") }}</div>
                <div class="textproduct2">{{ $t("index.products3") }}</div>
                <div class="textproduct2">{{ $t("index.products4") }}</div>
                <div
                  style="
                    width: 100%;
                    height: 7.5rem;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <!-- <div
                  style="
                    width: 200px;
                    height: 55px;
                    position: absolute;
                    bottom: -25px;
                    background-color: #5679fb;
                    text-align: center;
                    line-height: 55px;
                    border-radius: 10px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #ffffff;
                  "
                >
                  {{ $t("index.productsB") }}
                </div> -->
                </div>
              </div>
              <div class="productbox-content">
                <div
                  style="
                    width: 100%;
                    height: 7.5rem;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      width: 9.1875rem;
                      height: 9.1875rem;
                      position: absolute;
                      top: -3.1875rem;
                    "
                  >
                    <img src="../assets/product2.png" alt="" />
                  </div>
                </div>
                <div
                  style="display: flex; justify-content: center"
                  class="textproduct"
                >
                  {{ $t("index.products5") }}
                </div>
                <div class="textproduct2">{{ $t("index.products6") }}</div>
                <div class="textproduct2">{{ $t("index.products7") }}</div>
                <div class="textproduct2">{{ $t("index.products8") }}</div>
                <div class="textproduct2">{{ $t("index.products9") }}</div>
                <div
                  style="
                    width: 100%;
                    height: 120px;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <!-- <div
                style="
                    width: 200px;
                    height: 55px;
                    position: absolute;
                    bottom: -25px;
                    background-color: #5679fb;
                    text-align: center;
                    line-height: 55px;
                    border-radius: 10px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #ffffff;
                  "
                >
                  {{ $t("index.productsB") }}
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 业绩展示 -->
        <div class="box6">
          <div
            id="part6"
            style="
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 100px;
            "
          >
            <div class="developmentTitle-1" style="color: #333333">
              {{ $t("index.PerformanceP") }}
            </div>
            <!-- <div class="developmentTitle-2" style="color: #aaaaaa">
            /ACHIEVEMENTS
          </div> -->
          </div>
          <div
            style="
              display: flex;
              width: 100%;
              height: 600px;
              justify-content: center;
              margin-top: 76px;
            "
          >
            <div class="achievements">
              <div style="display: flex; height: 50%; width: 50%">
                <div style="flex: 1" class="achimg">
                  <div class="imgcontent">
                    <div
                      style="text-align: center; height: 50px; margin-top: 25px"
                      class="textclass"
                    >
                      {{ $t("index.achievements1") }}
                    </div>
                    <div
                      style="text-align: center; height: 44px; margin-top: 18px"
                      class="textclass2"
                    >
                      {{ $t("index.achievements2") }}
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; height: 50%; width: 50%">
                <div style="flex: 1" class="achimg2">
                  <div class="imgcontent">
                    <div
                      style="text-align: center; height: 50px; margin-top: 20px"
                      class="textclass"
                    >
                      {{ $t("index.achievements3") }}
                      <br />&nbsp;{{ $t("index.achievements") }}
                    </div>
                    <div
                      style="text-align: center; height: 44px; margin-top: 18px"
                      class="textclass2"
                    >
                      {{ $t("index.achievements4") }}
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; height: 50%; width: 50%">
                <div style="flex: 1" class="achimg2">
                  <div class="imgcontent">
                    <div
                      style="
                        text-align: center;
                        height: 50px;
                        margin-top: 25px;
                        overflow-wrap: break-word;
                      "
                      class="textclass"
                    >
                      {{ $t("index.achievements5") }}
                    </div>
                    <div
                      style="text-align: center; height: 44px; margin-top: 18px"
                      class="textclass2"
                    >
                      {{ $t("index.achievements6") }}
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; height: 50%; width: 50%">
                <div style="flex: 1" class="achimg">
                  <div class="imgcontent">
                    <div
                      style="text-align: center; height: 50px; margin-top: 25px"
                      class="textclass"
                    >
                      {{ $t("index.achievements7") }}
                    </div>
                    <div
                      style="text-align: center; height: 44px; margin-top: 18px"
                      class="textclass2"
                    >
                      {{ $t("index.achievements8") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 我们的资质 -->
        <div class="box7">
          <div
            id="part7"
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              padding-top: 101px;
            "
          >
            <div class="developmentTitle-1">{{ $t("index.OurQ") }}</div>
            <!-- <div class="developmentTitle-2" style="opacity: 1">
            /OUR QUALIFICATIONS
          </div> -->
          </div>
          <div
            style="
              width: 100%;
              height: 306px;
              display: flex;
              justify-content: center;
              margin-top: 60px;
            "
          >
            <div
              style="
                height: 100%;
                width: 1200px;
                display: flex;
                justify-content: center;
              "
            >
              <div style="margin-right: 38px; flex: 1" class="zhengshu1"></div>
              <div style="margin-right: 38px; flex: 1" class="zhengshu2"></div>
              <div style="margin-right: 38px; flex: 1" class="zhengshu3"></div>
              <div style="margin-right: 38px; flex: 2" class="zhengshu4"></div>
            </div>
          </div>
        </div>
        <!-- 联系我们 -->
        <div class="box8">
          <div class="box8-left">
            <div
              id="part8"
              style="
                margin-top: 42px;
                width: 60%;
                display: flex;
                padding-left: 25%;
              "
            >
              <div>
                <div class="box8left-title">{{ $t("index.companyName") }}</div>
                <div style="height: 128px" class="box8text">
                  <div>{{ $t("index.addressD") }}</div>
                  <div>{{ $t("index.phone") }}</div>
                  <div>{{ $t("index.EnglishPhone") }}</div>
                  <div>{{ $t("index.website") }}</div>
                  <div>{{ $t("index.email") }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="box8-right">
          <div style="margin-left: 82px; margin-top: 42px">
            <div class="box8left-title" style="color: #666666">
              {{ $t("index.ContactUs") }}
            </div>
            <div class="box8text2">
              <div
                style="
                  border-bottom: 1px solid #666666;
                  width: 283px;
                  margin-bottom: 24px;
                  display: flex;
                "
              >
                <div>
                  <img src="../assets/small1.png" alt="" />
                </div>
                <div style="margin-left: 10px">
                  <input type="text" style="border: none; color: #ffffff;  background-color: transparent;">
                </div>
              </div>
              <div
                style="
                  border-bottom: 1px solid #666666;
                  width: 283px;
                  margin-bottom: 24px;
                  display: flex;
                "
              >
                <div>
                  <img src="../assets/small2.png" alt="" />
                </div>
                <div style="margin-left: 10px">
                  <input type="text" style="border: none; color: #ffffff;  background-color: transparent;">
                </div>
              </div>
              <div
                style="
                  border-bottom: 1px solid #666666;
                  width: 283px;
                  margin-bottom: 24px;
                  display: flex;
                "
              >
                <div>
                  <img src="../assets/small3.png" alt="" />
                </div>
                <div style="margin-left: 10px">
                  <input type="text" style="border: none;color: #ffffff;  background-color: transparent;">
                </div>
              </div>
              <div
                style="
                  border-bottom: 1px solid #666666;
                  width: 283px;
                  margin-bottom: 24px;
                  display: flex;
                "
              >
                <div>
                  <img src="../assets/neirong.png" alt="" />
                </div>
                <div style="margin-left: 10px">
                  <input type="text" style="border: none; color: #ffffff; width: 100%; background-color: transparent;">
                </div>
              </div>
            </div>
            <div
              style="
                width: 282px;
                height: 52px;
                background-color: #5679fb;
                line-height: 52px;
                text-align: center;
                color: #ffffff;
                margin-top: 22px;
              "
            >
              {{ $t("index.submit") }}
            </div>
          </div>
        </div> -->
        </div>
        <div class="box9">
          <div class="box9text">
            {{ $t("index.companyName") }}
          </div>
        </div>
      </div>
    </BaseResizeFrame>
    </div>
</template>

<script>
import BaseResizeFrame from "@/frames/BaseResizeFrame";
export default {
  components: {
    BaseResizeFrame,
  },
  data() {
    return {
      language: false,
      baseWidth: 2360,
      // baseWidth: 1920,
      baseHeight: 1080,
    };
  },
  methods: {
    changeLanguage() {
      if (this.language == true) {
        this.$i18n.locale = "en";
        localStorage.setItem("languageSet", "en");
        console.log(localStorage.getItem("languageSet"), "en");
      } else {
        this.$i18n.locale = "zh";
        localStorage.setItem("languageSet", "zh");
        console.log(localStorage.getItem("languageSet"), "zh");
      }
    },
  },
};
</script>

<style scoped>
.home {
  width: 100vw;
  height: 100vh;
}
.top {
  width: 100%;
  height: 1020px;
  background-image: url(../assets/topimg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.topnav {
  width: 100%;
  height: 70px;
  position: absolute;
  display: flex;
  top: 29px;
}
.topnav > div {
  display: flex;
}
.navtext {
  width: 150px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-switch__label--left {
  color: #ffffff; /* 设置活动文本的字体颜色为活动颜色 */
}
::v-deep .el-switch__label--right {
  color: #ffffff; /* 设置活动文本的字体颜色为活动颜色 */
}
.donglogo {
  position: absolute;
  top: 240px;
  left: 380px;
  width: 189px;
  height: 148px;
}
.navcontent {
  padding: 0 5px 0 5px;
  width: 180px;
  height: 38px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 21px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company {
  height: 69px;
  position: absolute;
  top: 411px;
  left: 360px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 80px;
  color: #ffffff;
}
.companyText {
  width: 838px;
  height: 78px;
  position: absolute;
  top: 515px;
  left: 360px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 30px;
  color: #ffffff;
  text-align: left;
}
.companyBtn {
  width: 184px;
  height: 53px;
  position: absolute;
  top: 683px;
  left: 360px;
  line-height: 53px;
  text-align: center;
  background-color: #2dc173;
  color: #ffffff;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  border-radius: 10px;
}
.box2 {
  width: 100%;
  height: 868px;
  position: relative;
  margin-bottom: 102px;
}
.introduction {
  width: 100%;
  height: 50px;
  position: absolute;
  top: 108px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.introductionContent {
  width: 100%;
  height: 414px;
  position: absolute;
  top: 244px;
  display: flex;
  justify-content: center;
}
.introductionContent-text {
  width: 594px;
  margin-right: 48px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  text-align: justify;
  font-size: 20px;
  color: #333333;
  line-height: 35px;
  word-wrap: break-word;
  hyphens: auto;
}
.introductionContent-img {
  width: 594px;
  background-image: url(../assets/img-1.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.box3 {
  width: 100%;
  height: 730px;
  background-image: url(../assets/img-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.developmentTitle {
  position: absolute;
  top: 91px;
  left: 60px;
  height: 47px;
  display: flex;
}
.developmentTitle-1 {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 48px;
  color: #ffffff;
}
.developmentTitle-2 {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 48px;
  color: #ffffff;
  opacity: 0.6;
}
.developmentContent {
  position: absolute;
  top: 163px;
  width: 100%;
  height: 530px;
}
.developmentContent-top {
  border-bottom: 3px solid rgba(248, 248, 248, 0.5);
  height: 240px;
  display: flex;
}
.developtext {
  border-right: 2px solid rgba(248, 248, 248, 0.5);
  flex: 1;
  width: 25rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  position: relative;
}
.developtextYear {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 35px;
  color: #ffffff;
}
.developmentContent-bottom {
  height: 284px;
  display: flex;
}
.developtext2 {
  border-right: 2px solid rgba(248, 248, 248, 0.5);
}
.devetxt {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  margin-top: 20px;
  margin-left: 25px;
  line-height: 36px;
}
.box4 {
  width: 100%;
  height: 520px;
  position: relative;
}
.visionContent {
  margin-top: 81px;
  height: 230px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.visionContent-card {
  /* width: 62.5%; */
  width: 1180px;
  height: 230px;
  background: #ffffff;
  box-shadow: 0px 3px 9px 1px rgba(35, 24, 21, 0.15);
  border-radius: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.cardContent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.cardContent2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContent-title {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 28px;
  color: #5679fb;
  line-height: 14px;
}
.cardContent-text {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 20px;
  color: #5679fb;
  line-height: 30px;
  margin-top: 10px;
}
.box5 {
  width: 100%;
  height: 660px;
  position: relative;
}
.productbox {
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: center;
}
.productbox-content {
  width: 589px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 9px 1px rgba(35, 24, 21, 0.15);
  border-radius: 10px;
  position: relative;
  border: 1px solid #5679fb;
}
.textproduct {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 28px;
  color: #5679fb;
  line-height: 20px;
}
.textproduct2 {
  display: flex;
  justify-content: center;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 23px;
  margin-top: 20px;
}
.box6 {
  width: 100%;
  height: 948px;
}
.achievements {
  width: 1200px;
  height: 660px;
  display: flex;
  flex-wrap: wrap;
}
.achimg {
  background-image: url(../assets/ach1.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.achimg2 {
  background-image: url(../assets/ach2.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgcontent {
  width: 500px;
  height: 200px;
  background: rgba(86, 121, 251, 0.85);
}
.textclass {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 25px;
  color: #ffffff;
}
.textclass2 {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 19px;
  color: #ffffff;
}
.box7 {
  width: 100%;
  height: 600px;
  background-image: url(../assets/ourback.png);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
.zhengshu1 {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../assets/zhengshu1.png);
}
.zhengshu2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../assets/zhengshu2.png);
}
.zhengshu3 {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../assets/zhengshu3.png);
}
.zhengshu4 {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../assets/zhengshu4.png);
}
.box8 {
  width: 100%;
  height: 391px;
  display: flex;
}
.box8-left {
  flex: 1;
  height: 100%;
  background-color: #333333;
}
.box8-right {
  flex: 1;
  height: 100%;
  background-color: #191919;
}
.box8left-title {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 40px;
  color: #ffffff;
  margin-bottom: 50px;
}
.box8text {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 23px;
  color: #999999;
  line-height: 36px;
}
.box8text2 {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #999999;
}
::v-deep .el-switch__label{
  font-size: 22px;
}
.box9 {
  width: 100%;
  height: 72px;
  background-color: #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box9text {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 21px;
  color: #ffffff;
}
.navcontent:hover {
  background-color: #ffffff;
  color: #4463ae !important;
  cursor: pointer;
}
input:focus {
  outline: 1px solid transparent;
}
</style>
