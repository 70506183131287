
const data = {
    ZH: {
        index: "首页",
        company: '公司介绍',
        developmentH: '发展历程',
        CorporateV: '企业愿景',
        ProductsAndS: '产品与服务',
        ProductsAndS2: '产品与服务',
        PerformanceP: '业绩展示',
        OurQ: '我们的资质',
        OurQ2: '我们的资质',
        ContactUs: '联系我们',
        companyName: '云南东阳科技有限公司',
        companyDetail: '积累大量工业互联网安全建设项目案例，具备丰富安全实施经验 为服务社会，实现多方共赢',
        introduction1:'云南东阳科技是一家综合性产品及服务解决方案提供商，致力于IT, 通讯，科技，商用及消费类领域。',
        introduction2:'东阳科技以客户需求为中心，搜寻高质量的产品并提供相应的服务解决方案。同时培养技术过硬的服务团队，保证产品的售后服务及解决方案的实施。',
        introduction3:'东阳科技多年来不断提高和完善自身的技术水平、管理水平、服务水平、质量保证能力等要素，充分发挥在技术、人才、产品、管理等方面的优势，注重知识与资本的高效结合，取得来多项荣誉与资质，树立了东阳科技良好的资信和公众形象。',
        introduction4:'经过十余年的不断耕耘，我公司主要业务集中在政府单位、高校、司法、文化、电力、能源、生产型企业等领域，具有较高的行业认同及客户信誉。 东阳科技是ISO9001 ISO20000认证企业。',
        development1:"公司完成重组，业务方向扩展至信息 系统集成、互联网、安防工程等领域",
        development2:"完成机关骨干网络建设，为信息化高速发展提供了必要条件",
        development3:"获得云南省软件十强企业称号《 IS09001-2008质量管理体系认证》 《ISO20000计算机运维服务管理体系》完成多个软件的著作",
        development4:"和永中软件股份有限公司完成云南省软件 正版化和信创战略合作，提供专业的工业 安全解决方案、提供大数据治理解决方案， 提供心理矫治解决方案",
        development5:"公司完成全省16个州市128个县8000 多乡镇文化共享工程、全省金农工程 骨干网建设，为公司发展打下基础",
        development6:"审办了《电子与智能化工程专业承包 贰级》证书、《安全技术防范行业资 信证》壹级《云南省安全技术防范备 案证》等证书，获得云南省软件和信息化技术服务行业突出贡献奖。",
        development7:"完成《红河县教育信息化项目》《元阳县教育信息化项目》顺利进入教育 行业。",
        corporate1:'东阳核心价值',
        corporate2:'感恩 求真 专业',
        corporate3:'东阳使命',
        corporate4:'偕同全球伙伴 创建美好未来',
        corporate5:'东阳愿景',
        corporate6:'国际一流、中国最强 受人尊敬的国际公共采购市场服务商',
        products1:"商业、通讯、科技",
        products2:"IT，通讯和广播",
        products3:"办公设备、配件和耗材",
        products4:"照相摄影、专用音频和视频设备及零件",
        products5:"消费类",
        products6:"橡胶和塑料制品",
        products7:"青少年体育器材类产品",
        products8:"家具类",
        products9:"农业类",
        productsB:"查看详情",
        achievements1:"云南省文化信息资源共享工程",
        achievements2:"通过互联网加强机构和用户的连接，增进沟通交流频率，提升用户粘性",
        achievements3:"金农工程一期项目",
        achievements:"（云南省建设部分）",
        achievements4:"与省级数据中心形成一个完整、统一安全、可靠的网络整体",
        achievements5:"云南省青少年活动中心建设项目",
        achievements6:"增强体质，发展特长，对促进青少年健康成长发挥着重要作用",
        achievements7:"红河县教育信息化建设项目",
        achievements8:"推进教育均衡，逐步完善了教育信息化基础设施建设",
        addressD:'地址:云南省昆明市盘龙区世纪俊园二期4-2-1322',
        phone:'电话：+86 871 6541 2121',
        EnglishPhone:'英文客服电话：+86 13701217510',
        website:'网址：www.dongyoung.com.cn',
        email:'邮箱：tender@dongyoung.com.cn',
        submit:'提交',
    },
    EN: {
        index: "Home",
        company: 'About Us',
        developmentH: 'Milestones',
        CorporateV: 'Mission',
        ProductsAndS: 'Products',
        ProductsAndS2: 'Products & Services',
        PerformanceP: 'Our Projects',
        OurQ: 'Qualifications',
        OurQ2: 'Our Qualifications',
        ContactUs: 'Contact us',
        companyName: 'DONG YOUNG TECHNOLOGY & TRADE (CHINA) CO LTD',
        companyDetail: 'DONG YOUNG TECHNOLOGY & TRADE (CHINA) CO LTD is an experienced supplier of a broad range of products and service solutions. We focus on areas of IT, Communication, technology, business and consumer products.',
        introduction1:'Dong Young is customer oriented and dedicated in sourcing high quality products with service solutions. Our after-sales team, well equipped with product knowledge, ensure customer satisfaction.',
        introduction2:'Yunnan Dongyang Technology is a comprehensive product and service solution provider dedicated to the fields of IT, communication, technology, commercial and consumer sectors. Dongyang Technology focuses on customer needs, searching for high-quality products and providing corresponding service solutions. At the same time, cultivate a technically proficient service team to ensure the after-sales service of products and the implementation of solutions.',
        introduction3:"After more than a decade of efforts, we have maintained a comprehensive client portfolio of government, education, culture, power generation, energy and manufactures.",
        introduction4:"We are proud to be a SO9001 ISO20000 certified company.",
        development1:"Reorganization. Service scope extended to information, system integration, internet and related products supply",
        development2:"Completion of agriculture and public welfare projects in more than 8000 towns in 128 counties of 16 cities.",
        development3:"Establishment of key network infrastructure to ensure the speedy development of informatization.",
        development4:"Awards winning year! Multiple qualifications and awards in Electronical, AI engineering, Software, Informatization and Security Technology.",
        development5:"IS09001-2008 & ISO20000 certified. Awarded of Top 10 Enterprises of Yunnan Province",
        development6:"Developed clients in Educational area by completing main projects of Education Informatization of Hong He County and Yuan Yang County.",
        development7:"Partnered with Yong Zhong Software to provide professional safety solution and big data management solutions. Further developed into supplying agricultural products and business/consumer products.",
        corporate1:'Our Values',
        corporate2:'Gratitude, Integrity & Professionalism',
        corporate3:'Our Mission',
        corporate4:'A Better Future with Global Partners',
        corporate5:'Our Vision',
        corporate6:'To be a Respected Chinese Supplier of World-Class in the Global Market Place',
        products1:"Business, Communication & Technology",
        products2:"IT, Telecommunication & Broadcasting",
        products3:"Office Equipment, Components & Supplies",
        products4:"Photography, specialized audio and video equipment and parts",
        products5:"Consumer Products",
        products6:"Rubber & Plastic",
        products7:"Sports and Recreational Equipment and Supplies and Accessories",
        products8:"Furniture",
        products9:"Farm Products",
        productsB:"Detail",
        achievements1:"Yunan Provincial Cultural Information Resource Sharing Project",
        achievements2:"Strengthen the connection between institutions and users through the Internet, increase the frequency of communication and enhance user stickiness",
        achievements3:"Gold Agriculture Project Phase 1, Yunnan",
        achievements:"(Yunnan Province Construction Part)",
        achievements4:"Form a complete, unified, secure, and reliable network with provincial data centers",
        achievements5:"Yunnan Provincial Youth Activity Center Construction Project",
        achievements6:"Enhancing physical fitness and developing talents play an important role in promoting the healthy growth of adolescents",
        achievements7:"Hong He Country Education Informatization Project",
        achievements8:"The education informatization construction project in Honghe County has promoted education balance and gradually improved the infrastructure construction of education informatization",
        addressD:'Address: No 4-2-1322, Century Junyuan Phase II, Panlong District, Kunming, Yunnan Province China',
        phone:'Tel: +86 1370 121 7510 (For English Service) / +86 871 6541 2121 ',
        EnglishPhone:'English customer service hotline:+86 13701217510',
        website:'Website: www.dongyou.com',
        email:'Email: tender@dongyoung.com.cn',
        submit:'submit'
    }
}
export default data
